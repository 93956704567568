import { Component } from 'react';
import { Container } from 'react-bootstrap';
import MenuTop from './components/MenuTop';
import MenuLeft from './components/MenuLeft';
import AppContext, { createAppContext } from './context/AppContext';
import AppModal from './components/AppModal';
import "chartjs-plugin-colorschemes-v3/dist/chartjs-plugin-colorschemes-v3";


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement
} from 'chart.js';
import DashboardView from './pages/DashboardView';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement
);


class App extends Component {

    constructor(props){
        super(props);
        this.state = createAppContext(() => this.state, state => this.setState(state));
    }

    changeFullscreen = () => {
        this.setState({ fullscreen: !!document.fullscreenElement })
    }

    componentDidMount() {
        document.addEventListener('fullscreenchange', this.changeFullscreen);

        const urlParams = new URLSearchParams(window.location.search);
        
        const DashboardId = urlParams.get("DashboardId");
        if (DashboardId){
            this.state.fetchApi("GET", `/dashboard/${DashboardId}`)
            .then(response => {
                this.state.setContent(<DashboardView data={response.data} />);
            })
            .catch(error => {
                this.state.setContent(<Container className='mt-2'><h2>Erro</h2><hr /> {error.response?.data?.message}</Container>);
            })

        }
    }

    componentWillUnmount() {
        document.removeEventListener('fullscreenchange', this.changeFullscreen);
    }


    render() {
        return <AppContext.Provider value={this.state}>
            <Container fluid className='h-100 d-flex flex-column m-0 p-0 overflow-auto print-visible'>
                <MenuTop />
                <div className='flex-fill d-flex overflow-auto print-visible'>
                    <MenuLeft />
                    <div className='flex-fill overflow-auto print-visible'>
                        {this.state.content}
                    </div>
                </div>
            </Container>
            <AppModal />
        </AppContext.Provider >
    }

}

export default App;

