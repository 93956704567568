import { faCubes, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import AppContext from "../context/AppContext";
import Home from "../pages/Home";
import DashboardList from "../pages/DashboardList";

class MenuLeft extends Component {

    static contextType = AppContext;

    render() {
        return <div id="menu-left" className={`bg-dark text-white d-flex flex-column d-print-none ${ !this.context.showMenu ? "contract" : "" }`}>
            <div className="d-flex menu-label justify-content-between m-2 menu-title text-secondary" style={{ overflow: "hidden", height: 24, visibility: this.context.showMenu ? 'visible' : 'hidden' }}>Menu Principal</div>
            <div onClick={() => this.context.setContent(<Home />)} role="button" className="d-flex justify-content-between m-2" style={{ height: 24 }}><FontAwesomeIcon icon={faHome} /><span className={this.context.showMenu ? 'menu-label' : 'd-none'}>&nbsp;Página Inicial</span></div>
            <div onClick={() => this.context.setContent(<DashboardList />)} role="button" className="d-flex justify-content-between m-2" style={{ height: 24 }}><FontAwesomeIcon icon={faCubes} /><span className={this.context.showMenu ? 'menu-label' : 'd-none'}>&nbsp;Dashboards</span></div>
        </div>;
    }
}

export default MenuLeft;