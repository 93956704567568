import { createContext } from "react";
import Home from '../pages/Home';
import axios from 'axios';
import * as jwt from 'jwt-decode';

const AppContext = createContext();

export const createAppContext = (getContext, setContext) => ({
    usuario: null,
    token: "",
    checkPermission: (usuario, permissao) => usuario?.Vinculos?.find(v => v.Perfil?.Permissoes?.find(p => p.nome === permissao)) !== undefined,
    showModal: false,
    modalContent: null,
    modalSize: "md",
    modalTitle: "",
    openModal: (modalTitle, modalContent, modalSize) => setContext({ showModal: true, modalTitle, modalContent, modalSize }),
    closeModal: () => setContext({ showModal: false }),
    showMenu: true,
    content: <Home />,
    setContent: content => setContext({ content }),
    toggleMenu: () => setContext(state => ({ showMenu: !state.showMenu })),
    fetchApi: (method, url, data) => axios.request({
        method,
        url: process.env.REACT_APP_BACKEND_URL + url,
        data,
        headers: {
            "Authorization": `Bearer ${getContext().token}`
        }
    }),
    fetchAuth: (method, url, data) => axios.request({
        method,
        url: process.env.REACT_APP_AUTH_URL + url,
        data
    }),
    autenticar: (credentials) => axios.post(`${process.env.REACT_APP_AUTH_URL}/login`, credentials)
        .then(response => {
            const { token } = response.data;
            const usuario = jwt.jwtDecode(token);
            setContext({ usuario, token, content: <Home />});
        }),
    logout: () => setContext({ usuario: null, token: "", content: <Home /> })
});

export default AppContext;