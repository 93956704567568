import { Component } from "react";
import { Container } from "react-bootstrap";

class Home extends Component {

    render() {
        return <Container>

            <h2 className="mt-2">Home</h2>
            <hr />
            <h3>Bem-vindo ao Painel CBM!</h3>
            
            <p align="justify">Este ambiente foi criado para oferecer a você uma experiência completa de
            consulta e análise de dados dos principais sistemas do Corpo de Bombeiros Militar do Pará (CBMPA).
            Aqui, você terá acesso a informações estatísticas detalhadas e gráficos dinâmicos que podem ser
            personalizados para atender às suas necessidades específicas.</p>
            
            <p align="justify">Explore as diversas funcionalidades do painel e aproveite uma visualização clara e objetiva das
            métricas e resultados essenciais para apoiar a tomada de decisões e o aprimoramento das atividades.
            Estamos comprometidos em fornecer uma ferramenta robusta, eficiente e orientada aos objetivos
            institucionais do CBMPA.</p>

        </Container>
    }

}

export default Home;

