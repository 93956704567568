import { Component } from "react";
import { Table } from "react-bootstrap";

class TableView extends Component {

    static defaultProps = {
        data: []
    }

    render() {

        const headers = this.props.data.length ? Object.keys(this.props.data[0]) : [];

        return <div className="w-100 overflow-auto print-visibleY">
            <Table>
                <thead>
                    <tr>
                        {headers.map((header, key) => <th key={key}>{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.map((row, key) =>
                        <tr key={key}>
                            {headers.map((header, key) => <td key={key}>{row[header]}</td>)}
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>;
    }
}

export default TableView;