import { faCubes, faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import AppContext from "../context/AppContext";
import DashboardForm from "./DashboardForm";
import DashboardView from "./DashboardView";

class DashboardList extends Component {

    static contextType = AppContext;

    state = {
        dashboards: []
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.context.closeModal();
        this.context.fetchApi("GET", "/dashboard").then(response => this.setState({ dashboards: response.data })).catch(console.log);
    }

    view(data) {
        this.context.setContent(<DashboardView data={data} />);
    }

    create(data) {
        this.context.fetchApi("POST", "/dashboard", data).then(() => this.refresh()).catch(console.log);
    }

    update(id, data) {
        this.context.fetchApi("PUT", `/dashboard/${id}`, data).then(() => this.refresh()).catch(console.log);
    }

    delete(id) {
        if (window.confirm("Deseja realmente excluir este item?"))
            this.context.fetchApi("DELETE", `/dashboard/${id}`).then(() => this.refresh()).catch(console.log);
    }

    render() {
        return <Container>
            <h2 className="mt-2 d-flex justify-content-between align-items-center">
                Dashboards
                {this.context.checkPermission(this.context.usuario, "Adicionar Dashboard") &&
                    <Button className="d-print-none" variant="outline-success" onClick={() => this.context.openModal("Adicionar Dashboard", <DashboardForm data={{}} onSave={data => this.create(data)} onCancel={() => this.context.closeModal()} />)}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Adicionar
                    </Button>}
            </h2>
            <hr />
            <Row>
                {(this.state.dashboards.length && this.state.dashboards.map((dashboard, key) =>
                    <Col md={6} lg={4} key={key}>
                        <Card className="mb-2">
                            <Card.Header className="d-flex alig-items-center justify-content-between align-items-center">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faCubes} />&nbsp;
                                    {dashboard.nome ?? "Sem nome"}
                                </Card.Title>
                                <div className="text-nowrap d-print-none">
                                    {this.context.checkPermission(this.context.usuario, "Editar Dashboard") &&
                                        <Button className="me-2" size="sm" variant="primary" onClick={() => this.context.openModal("Editar Dashboard", <DashboardForm data={dashboard} onSave={data => this.update(dashboard.id, data)} onCancel={() => this.context.closeModal()} />)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>}
                                    {this.context.checkPermission(this.context.usuario, "Excluir Dashboard") &&
                                        <Button size="sm" variant="danger" onClick={() => this.delete(dashboard.id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>}
                                </div>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column justify-content-between ">
                                {dashboard.descricao}&nbsp;
                                <Button className="form-control mt-3" onClick={() => this.view(dashboard)}>
                                    <FontAwesomeIcon icon={faEye} />&nbsp;Visualizar
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                )) || <Col>Nenhum Dashboard disponível.</Col>}
            </Row>
        </Container>
    }

}

export default DashboardList;

