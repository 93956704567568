import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AppContext from "../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class OrdemForm extends Component {

    static contextType = AppContext;

    state = {
        id: this.props.data?.id ?? null,
        QuadroId: this.props.data?.QuadroId ?? null,
        atributo: this.props.data?.atributo ?? "",
        tipo: this.props.data?.tipo ?? "",
        agregacao: this.props.data?.agregacao ?? "",
        direcao: this.props.data?.direcao ?? "",

        tabela: "",
        atributos: [],
        tipos: [
            "",
            "Valor Exato",
            "Valor Inteiro",
            "Data",
            "Dia",
            "Mês",
            "Ano",
            "Dia/Mês",
            "Mês/Ano",
            "Dia da Semana",
            "Horário",
            "Hora",
            "Hora/Minuto",
            "Minuto",
            "Segundo"
        ],
        agregacoes: [
            "",
            "Máximo",
            "Mínimo",
            "Média",
            "Variância",
            "Desvio Padrão",
            "Soma",
            "Concatenação",
            "Contagem Total",
            "Contagem Distinta"
        ]
    }

    componentDidMount() {
        this.context.fetchApi("GET", `/quadro/${this.state.QuadroId}`)
            .then(response => this.setState({ tabela: response.data.tabela }, () => {
                this.context.fetchApi("GET", `/tabela/${this.state.tabela}/atributo`).then(response => this.setState({ atributos: response.data }));
            }))
            .catch(console.log);
    }

    submit(event) {
        event.preventDefault();
        const { id, QuadroId, atributo, tipo, agregacao, direcao } = this.state;
        this.props.onSave({ id, QuadroId, atributo, tipo, agregacao, direcao });
    }

    render() {

        return <Form onSubmit={e => this.submit(e)} className="m-2">
            <Form.Label><b>Atributo</b></Form.Label>
            <Form.Select required value={this.state.atributo} onChange={e => this.setState({atributo: e.target.value})}>
                <option></option>
                {this.state.atributos.map((atributo, key) => <option key={key}>{atributo.nome}</option>)}
            </Form.Select>
            <Form.Label><b>Tipo</b></Form.Label>
            <Form.Select required value={this.state.tipo} onChange={e => this.setState({tipo: e.target.value})}>
                {this.state.tipos.map((tipo, key) => <option key={key}>{tipo}</option>)}
            </Form.Select>
            <Form.Label><b>Agregação</b></Form.Label>
            <Form.Select value={this.state.agregacao} onChange={e => this.setState({ agregacao: e.target.value })}>
                {this.state.agregacoes.map((agregacao, key) => <option key={key}>{agregacao}</option>)}
            </Form.Select>
            <Form.Label><b>Direção</b></Form.Label>
            <Form.Select required value={this.state.direcao} onChange={e => this.setState({direcao: e.target.value})}>
                <option></option>
                {["Crescente", "Decrescente"].map((tipo, key) => <option key={key}>{tipo}</option>)}
            </Form.Select>

            <Row>
                <Col md="12" className="d-flex justify-content-end mt-2">
                    <Button variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="primary" type="submit" className="ms-2">
                        <FontAwesomeIcon icon={faCheck} /> Enviar
                    </Button>
                </Col>
            </Row>
        </Form>
    }

}

export default OrdemForm;

