import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import AppContext from "../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class DashboardForm extends Component {

    static contextType = AppContext;

    state = {
        nome: this.props.data?.nome ?? "",
        descricao: this.props.data?.descricao ?? "",
        GrupoId: this.props.data?.GrupoId ?? "",

        grupos: []
    }

    componentDidMount(){
        this.context.fetchAuth("GET", "/grupo").then(response => this.setState({grupos: response.data})).catch(console.log);
    }

    submit(event) {
        event.preventDefault();
        this.props.onSave(this.state);
    }

    render() {
        return <Form onSubmit={e => this.submit(e)} className="m-2">
            <Form.Group className="mb-3">
                <Form.Label><b>Nome</b></Form.Label>
                <Form.Control
                    type="text"
                    value={this.state.nome}
                    onChange={e => this.setState({ nome: e.target.value })}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><b>Descrição</b></Form.Label>
                <Form.Control
                    as="textarea"
                    value={this.state.descricao}
                    onChange={e => this.setState({ descricao: e.target.value })}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><b>Grupo</b></Form.Label>
                <Form.Select                    
                    value={this.state.GrupoId}
                    onChange={e => this.setState({ GrupoId: e.target.value })}
                >
                    <option value="">Público</option>
                    {this.state.grupos.filter(g => g.id === this.state.GrupoId || this.context.usuario.Vinculos.find(v => v.GrupoId === g.id)).map((grupo, key) => <option value={grupo.id} key={key}>{grupo.nome}</option>)}
                </Form.Select>
                
            </Form.Group>

            <Form.Group className="d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.props.onCancel()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon={faCheck} /> Enviar
                </Button>
            </Form.Group>
        </Form>;
    }

}

export default DashboardForm;

