import { Component } from "react";
import { Card } from "react-bootstrap";

class ListView extends Component {

    static defaultProps = {
        data: []
    }

    render() {

        const headers = this.props.data.length ? Object.keys(this.props.data[0]) : [];

        return <div className="flex-fill d-flex flex-row flex-wrap">
            {this.props.data.map((row, key) =>
                <div key={key} className="flex-fill d-flex flex-column p-1">
                    <Card className="d-flex flex-fill">
                        <Card.Header className="text-nowrap bg-primary text-white">{row[headers[0]]}</Card.Header>
                        <Card.Body className="flex-fill d-flex flex-column justify-content-end">
                            {headers.slice(1).map((label, key) =>
                                <div key={key} className="mb-2">
                                    <b className="small">{label}</b>: <div className="text-end">{row[label] ?? '-'}</div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>)}
        </div>;
    }
}

export default ListView;