import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';

class BarView extends Component {

    state = {
        data: null
    }

    render() {

        const rows = this.props.data;

        const headers = rows.length ? Object.keys(rows[0]) : [];

        const labels = rows.map(row => row[headers[0]]);

        const datasets = headers.slice(headers.length > 1 ? 1 : 0).map(label => ({
            label,
            data: rows.map(row => Number(row[label])).map(number => isNaN(number) ? 0 : number)
        }));

        const options = {
            responsive: true,
            maintainAspectRatio: false, // Permite ajustar a proporção do gráfico    
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                },
                colorschemes: {
                    scheme: 'tableau.Tableau20'
                }
            },
        };

        return <Bar className="quadro-chart" options={options} data={{ labels, datasets }} plugins={[{
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart) => {
                const ctx = chart.canvas.getContext('2d');
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = '#FFFFFF'; // Cor do fundo
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        }]} />
    }

}

export default BarView;

