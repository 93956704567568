import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

class DashboardShare extends Component {

    copy() {
        navigator.clipboard.writeText(this.props.url)
            .then(() => {
                alert("Link copiado com sucesso!");
            })
            .catch((err) => {
                console.error("Falha ao copiar: ", err);
            })
    }

    render() {
        return (
            <Row>
                <Col md={12} className='d-flex flex-column'>
                    <Form.Group controlId="" className="mb-3">
                        <Form.Label>Link de Compartilhamento</Form.Label>
                        <Form.Control disabled value={this.props.url} />
                    </Form.Group>
                </Col>
                <Col md={12} className='d-flex'>
                    <Button className="ms-auto" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /> Fechar
                    </Button>
                    <Button className="ms-2" variant="primary" onClick={() => this.copy()}>
                        <FontAwesomeIcon icon={faCopy} /> Copiar Link
                    </Button>
                </Col>
            </Row >
        );
    }
}

export default DashboardShare;
