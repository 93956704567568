import { Component } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import AppContext from "../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarDay, faCheck, faClock, faFile, faFont, faHashtag, faQuestion, faTimes, faToggleOn } from "@fortawesome/free-solid-svg-icons";

class QuadroForm extends Component {

    static contextType = AppContext;

    state = {
        id: this.props.data?.id ?? null,
        DashboardId: this.props.data?.DashboardId ?? null,
        titulo: this.props.data?.titulo ?? "",
        tipo: this.props.data?.tipo ?? "",
        tabela: this.props.data?.tabela ?? "",
        posicao: this.props.data?.posicao ?? "0",
        limite: this.props.data?.limite ?? "10",
        tamanho: this.props.data?.tamanho ?? "4",

        tabelas: [],
        campos: [],
        tipos: [
            "",
            "Tabela",
            "Lista de Indicadores",
            "Gráfico de Barra Horizontal",
            "Gráfico de Barra Vertical",
            "Gráfico de Pizza",
            "Gráfico de Linha"
        ],
        tamanhos: [
            "",
            "1x1",
            "1x2",
            "1x3",
            "1x4",
            "2x1",                        
            "2x2",
            "2x3",
            "2x4"            
        ]
    }

    componentDidMount() {
        this.refreshTabelas();
    }

    refreshCampos() {
        this.context.fetchApi("GET", `/tabela/${this.state.tabela}/atributo`).then(response => this.setState({ campos: response.data })).catch(console.log);
    }

    refreshTabelas() {
        this.context.fetchApi("GET", "/tabela")
            .then(response => this.setState({ tabelas: response.data }, () => {
                if (this.state.tabela) {
                    this.refreshCampos();
                }
            })).catch(console.log);
    }

    submit(event) {
        event.preventDefault();
        const { id, DashboardId, tipo, titulo, tabela, limite, posicao, tamanho } = this.state;
        this.props.onSave({ id, DashboardId, tipo, titulo, tabela, limite, posicao, tamanho });
    }

    render() {

        const icons = {
            "text": faFont,
            "number": faHashtag,
            "boolean": faToggleOn,
            "date": faCalendarDay,
            "datetime-local": faCalendar,
            "time": faClock,
            "textarea": faFile
        }

        return <Form onSubmit={e => this.submit(e)} className="m-2">
            <Row>
                <Col md={8}>
                    <Row>
                        <Col md="12" className="mb-3">
                            <Form.Label><b>Título</b></Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="titulo"
                                value={this.state.titulo}
                                onChange={e => this.setState({ titulo: e.target.value })}
                            />
                        </Col>

                        <Col md="8" className="mb-3">
                            <Form.Label><b>Tabela</b></Form.Label>
                            <Form.Select
                                required
                                as="select"
                                name="tabela"
                                value={this.state.tabela}
                                onChange={e => this.setState({ tabela: e.target.value }, () => this.refreshCampos())}
                            >
                                <option></option>
                                {this.state.tabelas.map((tabela, key) => <option key={key}>{tabela}</option>)}
                            </Form.Select>
                        </Col>

                        <Col md="4" className="mb-3">
                            <Form.Label><b>Limite (Máx. 1000)</b></Form.Label>
                            <Form.Control
                                required
                                type="number"
                                min="0"
                                max="1000"
                                step="1"
                                name="limite"
                                value={this.state.limite}
                                onChange={e => this.setState({ limite: e.target.value })}
                            />
                        </Col>

                        <Col md="6" className="mb-3">
                            <Form.Label><b>Tipo</b></Form.Label>
                            <Form.Select
                                required
                                as="select"
                                name="tipo"
                                value={this.state.tipo}
                                onChange={e => this.setState({ tipo: e.target.value })}
                            >
                                {this.state.tipos.map((tipo, key) => <option key={key}>{tipo}</option>)}
                            </Form.Select>
                        </Col>
                        <Col md="6" className="mb-3">
                            <Form.Label><b>Tamanho</b></Form.Label>
                            <Form.Select
                                required
                                as="select"
                                value={this.state.tamanho}
                                onChange={e => this.setState({ tamanho: e.target.value })}
                            >
                                {this.state.tamanhos.map((tamanho, key) => <option key={key}>{tamanho}</option>)}
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <Col md="12" className="mb-3">
                            <Form.Label><b>Atributos da Tabela</b></Form.Label>
                            <ListGroup variant="flush">
                                {this.state.campos.map((campo, key) =>
                                    <ListGroup.Item key={key} className="d-flex justify-content-between">
                                        {campo.nome} <FontAwesomeIcon icon={icons[campo.tipo] ?? faQuestion} />
                                    </ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                        <FontAwesomeIcon icon={faCheck} /> Enviar
                    </Button>
                </Col>
            </Row>
        </Form>
    }

}

export default QuadroForm;

