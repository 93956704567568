import { Component } from "react";
import AppContext from "../context/AppContext";
import TableView from "../components/TableView";
import BarView from "../components/BarView";
import ListView from "../components/ListView";
import LineView from "../components/LineView";
import PizzaView from "../components/PizzaView";
import HorizontalBarView from "../components/HorizontalBarView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class QuadroView extends Component {

    static contextType = AppContext;

    state = {
        data: [],
        error: false,
        loading: true
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quadro !== this.props.quadro) {
            this.fetchData();
        }
    }

    fetchData() {
        this.setState({ loading: true }, () => {
            this.context.fetchApi("GET", `/quadro/${this.props.quadro.id}/data`)
                .then(({ data }) => {
                    this.setState({ data, error: false })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({error: `Erro na consulta: ${error.response.data.message}`});
                })
                .finally(() => {
                    this.setState({ loading: false });
                })
        })

    }

    render() {

        const Componente = {
            "Tabela": TableView,
            "Lista de Indicadores": ListView,
            "Gráfico de Barra Horizontal": HorizontalBarView,
            "Gráfico de Barra Vertical": BarView,
            "Gráfico de Pizza": PizzaView,
            "Gráfico de Linha": LineView,
        }[this.props.quadro.tipo] ?? TableView;

        if (this.state.loading) {
            return <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <FontAwesomeIcon icon={faSpinner} spin size="5x" />
            </div>;
        }
        else if (this.state.error) {
            return <div className="d-flex align-items-center justify-content-center w-100 h-100 text-center">
                {this.state.error}
            </div>
        } else return <Componente quadro={this.props.quadro} data={this.state.data} />
    }

}

export default QuadroView;

