import { Button, Container, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCompress, faExpand, faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import AppContext from "../context/AppContext";
import LoginForm from "../pages/LoginForm";

class MenuTop extends Component {

    static contextType = AppContext;

    state = {
        fullscreen: false
    }

    toggleFullscreen() {

        const element = document.body;
        const fullscreen = document.fullscreenElement;

        if (fullscreen) {
            document.exitFullscreen()
                .then(() => {
                    this.forceUpdate();
                }).catch(console.log);;
        } else {
            element.requestFullscreen()
                .then(() => {
                    this.forceUpdate();
                }).catch(console.log);
        }
    }

    logon(credentials) {
        this.context.autenticar(credentials)
            .then(() => this.context.closeModal())
            .catch(error => {
                console.log(error);
                window.alert("Usuário ou senha inválida.")
            });
    }

    render() {
        const fullscreen = document.fullscreenElement;
        return <Navbar expand="lg" className="d-print-none bg-danger" >
            <Container fluid>
                <Navbar.Brand className="d-flex justify-content-between text-white" style={{ width: 160 }}>
                    Painel CBM
                    <Button variant="flush" className="ms-2 text-white d-md-block d-none" onClick={() => this.context.toggleMenu()}><FontAwesomeIcon icon={faBars} /></Button>
                </Navbar.Brand>
                <Navbar.Text className="text-white">{this.context.usuario?.nome_exibicao}</Navbar.Text>
                <div className="ms-auto">
                    <Button title={fullscreen ? "Tela Normal" : "Tela Cheia"} className="ms-2" variant="danger" onClick={() => this.toggleFullscreen()}>
                        <FontAwesomeIcon icon={fullscreen ? faCompress : faExpand} />
                    </Button>
                    <Button className="ms-2" variant="danger" onClick={() => this.context.usuario ? this.context.logout() : this.context.openModal("Efetuar Logon", <LoginForm onSave={data => this.logon(data)} />)}>
                        <FontAwesomeIcon icon={this.context.usuario ? faSignOut : faSignIn} />
                    </Button>
                </div>

            </Container>
        </Navbar>;
    }
}

export default MenuTop;