import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

class LoginForm extends Component {

    state = {
        login: "",
        password: ""
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.onSave(this.state);
    };

    render() {
        return (
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Login</Form.Label>
                            <Form.Control
                                value={this.state.login}
                                onChange={e => this.setState({ login: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Entrar
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default LoginForm;
