import { Component } from "react";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import AppContext from "../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faEdit, faFastBackward, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import CampoForm from "./CampoForm";
import FiltroForm from "./FiltroForm";
import GrupoForm from "./GrupoForm";
import OrdemForm from "./OrdemForm";
import QuadroView from "./QuadroView";

class QuadroConfig extends Component {

    static contextType = AppContext;

    state = {
        Campos: [],
        Filtros: [],
        Grupos: [],
        Ordens: []
    }

    componentDidMount() {
        this.refreshAll();
    }

    refreshAll() {
        this.refreshCampos();
        this.refreshFiltros();
        this.refreshGrupos();
        this.refreshOrdens();
    }
    refreshCampos() {
        this.context.fetchApi("GET", `/quadro/${this.props.data.id}/campo`).then(response => this.setState({ Campos: response.data })).catch(console.log);
    }
    refreshFiltros() {
        this.context.fetchApi("GET", `/quadro/${this.props.data.id}/filtro`).then(response => this.setState({ Filtros: response.data })).catch(console.log);
    }
    refreshGrupos() {
        this.context.fetchApi("GET", `/quadro/${this.props.data.id}/grupo`).then(response => this.setState({ Grupos: response.data })).catch(console.log);
    }
    refreshOrdens() {
        this.context.fetchApi("GET", `/quadro/${this.props.data.id}/ordem`).then(response => this.setState({ Ordens: response.data })).catch(console.log);
    }

    addCampo() {
        this.context.openModal(
            "Adicionar Campo",
            <CampoForm
                data={{ QuadroId: this.props.data.id }}
                onSave={data => { this.upsertCampo(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    editCampo(campo) {
        this.context.openModal(
            "Editar Campo",
            <CampoForm
                data={campo}
                onSave={data => { this.upsertCampo(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    addFiltro() {
        this.context.openModal(
            "Adicionar Filtro",
            <FiltroForm
                data={{ QuadroId: this.props.data.id }}
                onSave={data => { this.upsertFiltro(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    editFiltro(filtro) {
        this.context.openModal(
            "Editar Filtro",
            <FiltroForm
                data={filtro}
                onSave={data => { this.upsertFiltro(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    addGrupo() {
        this.context.openModal(
            "Adicionar Grupo",
            <GrupoForm
                data={{ QuadroId: this.props.data.id }}
                onSave={data => { this.upsertGrupo(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    editGrupo(grupo) {
        this.context.openModal(
            "Editar Grupo",
            <GrupoForm
                data={grupo}
                onSave={data => { this.upsertGrupo(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    addOrdem() {
        this.context.openModal(
            "Adicionar Ordem",
            <OrdemForm
                data={{ QuadroId: this.props.data.id }}
                onSave={data => { this.upsertOrdem(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }
    editOrdem(ordem) {
        this.context.openModal(
            "Editar Ordem",
            <OrdemForm
                data={ordem}
                onSave={data => { this.upsertOrdem(data); this.context.closeModal() }}
                onCancel={() => this.context.closeModal()}
            />);
    }

    confirm = () => window.confirm("Deseja realmente excluir este item?");
    upsertCampo = campo => this.upsert("campo", campo, () => this.refreshCampos());
    deleteCampo = campo => this.delete("campo", campo, () => this.refreshCampos());
    upsertGrupo = grupo => this.upsert("grupo", grupo, () => this.refreshGrupos());
    deleteGrupo = grupo => this.delete("grupo", grupo, () => this.refreshGrupos());
    upsertOrdem = ordem => this.upsert("ordem", ordem, () => this.refreshOrdens());
    deleteOrdem = ordem => this.delete("ordem", ordem, () => this.refreshOrdens());
    upsertFiltro = filtro => this.upsert("filtro", filtro, () => this.refreshFiltros());
    deleteFiltro = filtro => this.delete("filtro", filtro, () => this.refreshFiltros());

    upsert(model, data, callback) {
        this.context.fetchApi(
            data.id ? "PUT" : "POST",
            data.id ? `/${model}/${data.id}` : `/${model}`,
            data
        ).then(callback).catch(console.log);
    }

    delete(model, data, callback) {
        data.id &&
            this.context.fetchApi(
                "DELETE",
                `/${model}/${data.id}`
            ).then(callback).catch(console.log);
    }

    preview(){
        this.context.openModal(this.props.data.titulo, <QuadroView quadro={this.props.data} />);
    }

    submit(event) {
        event.preventDefault();
        this.props.onSave();
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.submit(e)} className="m-2">
                <h3 className="d-flex justify-content-between align-items-center">
                    <div className="text-nowrap">
                        <FontAwesomeIcon icon={faCogs} /> Configuração de Quadro
                    </div>
                    <Button variant="secondary" onClick={() => this.preview()}>
                        <FontAwesomeIcon icon={faSearch} /> Pré-visualizar
                    </Button>
                </h3>
                <hr />
                <Row>
                    <Col md="6" className="mb-3">
                        <Form.Label><b>Título</b></Form.Label>
                        <Form.Control
                            type="text"
                            value={this.props.data.titulo}
                            disabled
                        />
                    </Col>

                    <Col md="3" className="mb-3">
                        <Form.Label><b>Tabela</b></Form.Label>
                        <Form.Control
                            type="text"
                            value={this.props.data.tabela}
                            disabled>
                        </Form.Control>
                    </Col>

                    <Col md="3" className="mb-3">
                        <Form.Label><b>Tipo</b></Form.Label>
                        <Form.Control
                            type="text"
                            value={this.props.data.tipo}
                            disabled
                        >
                        </Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className="mb-5">
                        <h4 className=" d-flex justify-content-between algin-items-center">
                            Campos
                            <Button variant="success" size="sm" onClick={() => this.addCampo()}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar
                            </Button>
                        </h4>
                        <hr />
                        <ListGroup className="mb-2">
                            {this.state.Campos.map((campo, key) =>
                                <ListGroup.Item key={key}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <b>{campo.legenda ? campo.legenda : campo.atributo}</b><br />
                                            <i>{campo.agregacao ? `${campo.agregacao} de` : ``} {campo.tipo} de {campo.atributo}</i>
                                        </div>
                                        <div className="text-nowrap">
                                            <Button variant="outline-primary" size="sm" onClick={() => this.editCampo(campo)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-danger" size="sm" className="ms-2" onClick={() => this.confirm() && this.deleteCampo(campo)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Col>
                    <Col md="6" className="mb-5">
                        <h4 className=" d-flex justify-content-between algin-items-center">
                            Filtros
                            <Button variant="success" size="sm" onClick={() => this.addFiltro()}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar
                            </Button>
                        </h4>
                        <hr />
                        <ListGroup className="mb-2">
                            {this.state.Filtros.map((filtro, key) =>
                                <ListGroup.Item key={key}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {filtro.tipo} de {filtro.atributo} {filtro.operacao} {filtro.operacao && filtro.operacao.includes("Nulo") ? "" : `"${filtro.valor}"`}
                                        </div>
                                        <div className="text-nowrap">
                                            <Button variant="outline-primary" size="sm" onClick={() => this.editFiltro(filtro)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-danger" size="sm" className="ms-2" onClick={() => this.confirm() && this.deleteFiltro(filtro)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Col>
                    <Col md="6" className="mb-5">
                        <h4 className=" d-flex justify-content-between algin-items-center">
                            Agregações
                            <Button variant="success" size="sm" onClick={() => this.addGrupo()}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar
                            </Button>
                        </h4>
                        <hr />
                        <ListGroup className="mb-2">
                            {this.state.Grupos.map((grupo, key) =>
                                <ListGroup.Item key={key}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {grupo.tipo} de {grupo.atributo}
                                        </div>
                                        <div className="text-nowrap">
                                            <Button variant="outline-primary" size="sm" onClick={() => this.editGrupo(grupo)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-danger" size="sm" className="ms-2" onClick={() => this.confirm() && this.deleteGrupo(grupo)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Col>
                    <Col md="6" className="mb-5">
                        <h4 className=" d-flex justify-content-between algin-items-center">
                            Ordens
                            <Button variant="success" size="sm" onClick={() => this.addOrdem()}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar
                            </Button>
                        </h4>
                        <hr />
                        <ListGroup className="mb-2">
                            {this.state.Ordens.map((ordem, key) =>
                                <ListGroup.Item key={key}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                        {ordem.agregacao ? `${ordem.agregacao} de` : ``} {ordem.tipo} de {ordem.atributo} {ordem.direcao}
                                        </div>
                                        <div className="text-nowrap">
                                            <Button variant="outline-primary" size="sm" onClick={() => this.editOrdem(ordem)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-danger" size="sm" className="ms-2" onClick={() => this.confirm() && this.deleteOrdem(ordem)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }

}

export default QuadroConfig;

