import { faCogs, faEdit, faFileCsv, faFileExcel, faLink, faPlus, faPrint, faRefresh, faShareNodes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Button, Container, Dropdown, Form } from "react-bootstrap";
import AppContext from "../context/AppContext";
import QuadroForm from "./QuadroForm";
import QuadroView from "./QuadroView";
import QuadroConfig from "./QuadroConfig";
import * as XLSX from 'xlsx';
import DashboardShare from "./DashboardShare";

class DashboardView extends Component {

    static contextType = AppContext;

    state = {
        Quadros: [],
        data: [],

        dragStart: null,
        dragOver: null,

        autoRefresh: false
    }

    interval = null;

    componentDidMount() {
        this.refresh();
        this.interval = setInterval(() => {
            if (this.state.autoRefresh) this.refresh();
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getRowsFromData(data) {

        const headers = data.length ? Object.keys(data[0]) : [];

        const rows = [
            headers,
            ...data.map(row => headers.map(header => row[header]))
        ];

        return rows;
    }

    async getDataFromQuadro(quadro) {
        try {
            const response = await this.context.fetchApi("GET", `/quadro/${quadro.id}/data`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async downloadCSVFromQuadro(quadro) {

        const escapeCSV = (value) => {
            if (typeof value === "string") {
                // Substituir aspas duplas por duas aspas duplas
                value = value.replace(/"/g, '""');
                // Encapsular o campo entre aspas duplas se contiver caracteres especiais
                if (/[",\n]/.test(value)) {
                    value = `"${value}"`;
                }
            }
            return value;
        };

        const data = await this.getDataFromQuadro(quadro);
        const rows = this.getRowsFromData(data);

        const csvContent = rows.map(row => row.map(escapeCSV).join(', ')).join('\n');

        this.downloadContent(csvContent, "text/csv;charset=utf-8;", `Quadro-${quadro.id}.csv`);

    }

    async downloadXLSFromQuadro(quadro) {

        const data = await this.getDataFromQuadro(quadro);
        const rows = this.getRowsFromData(data);

        // 2. Cria a planilha (worksheet)
        const worksheet = XLSX.utils.aoa_to_sheet(rows);

        // 3. Cria o arquivo Excel (workbook)
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, quadro.titulo);

        // 4. Gera o arquivo Excel e faz o download
        XLSX.writeFile(workbook, `Quadro-${quadro.id}.xlsx`);

    }

    async downloadXLSFromDashboard() {

        const worksheets = await Promise.all(this.state.Quadros.map(async quadro => {
            const data = await this.getDataFromQuadro(quadro);
            const rows = this.getRowsFromData(data);
            return {
                title: quadro.titulo,
                sheet: XLSX.utils.aoa_to_sheet(rows)
            };
        }));

        const workbook = XLSX.utils.book_new();

        worksheets.forEach(({ title, sheet }) => XLSX.utils.book_append_sheet(workbook, sheet, title))

        XLSX.writeFile(workbook, `Dashboard-${this.props.data.id}.xlsx`);

    }

    downloadContent(content, type, filename) {
        const blob = new Blob([content], { type });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    refresh() {
        this.context.fetchApi("GET", `/dashboard/${this.props.data.id}/quadro`)
            .then(response => this.setState({ Quadros: response.data }))
            .catch(console.log);
    }

    toggleAutoRefresh() {
        this.setState(state => ({ autoRefresh: !state.autoRefresh }));
    }

    addQuadro() {
        this.context.openModal(
            "Adicionar Quadro",
            <QuadroForm
                data={{
                    id: null,
                    DashboardId: this.props.data.id,
                    posicao: this.state.Quadros.length
                }}
                onSave={data => this.upsertQuadro(data)}
                onCancel={() => this.context.closeModal()}
            />,
            "lg"
        );
    }

    editQuadro(quadro) {
        this.context.openModal(
            "Editar Quadro",
            <QuadroForm
                data={quadro}
                onSave={data => { this.context.closeModal(); this.upsertQuadro(data) }}
                onCancel={() => this.context.closeModal()}
            />,
            "lg"
        );
    }

    configQuadro(quadro) {
        this.context.setContent(
            <QuadroConfig
                data={quadro}
                onSave={() => this.context.setContent(<DashboardView data={this.props.data} />)}
                onCancel={() => this.context.setContent(<DashboardView data={this.props.data} />)}
            />
        )
    }

    upsertQuadro(data) {
        const method = data.id ? "PUT" : "POST"
        const url = data.id ? `/quadro/${data.id}` : `/quadro`;
        this.context.fetchApi(method, url, data).then(response => {
            this.context.closeModal();
            if (data.id) {
                this.setState(state => ({ Quadros: state.Quadros.map(q => q.id === data.id ? response.data : q) }));
            } else {
                this.setState(state => ({ Quadros: [...state.Quadros, response.data] }));
            }
        }).catch(console.log);
    }

    deleteQuadro(quadro) {
        quadro.id &&
            window.confirm("Deseja realmente excluir este item?") &&
            this.context.fetchApi("DELETE", `/quadro/${quadro.id}`).then(() => {
                this.context.closeModal();
                this.setState(state => ({ Quadros: state.Quadros.filter(q => q.id !== quadro.id) }))
            }).catch(console.log);
    }

    dragStart(event, quadro) {
        if (this.context.checkPermission(this.context.usuario, "Editar Dashboard")) {
            this.setState({ dragStart: quadro });
            event.dataTransfer.setDragImage(event.target.parentElement, 0, 0);
        }
    }
    dragEnd() {
        if (this.context.checkPermission(this.context.usuario, "Editar Dashboard")) {

            this.setState({ dragStart: null });
        }
    }
    dragOver(event, quadro) {
        if (this.context.checkPermission(this.context.usuario, "Editar Dashboard")) {

            event.preventDefault(); //Permite o drop
            this.setState({ dragOver: quadro });
        }
    }
    dragLeave() {
        if (this.context.checkPermission(this.context.usuario, "Editar Dashboard")) {

            this.setState({ dragOver: null });
        }
    }
    drop(event) {
        if (this.context.checkPermission(this.context.usuario, "Editar Dashboard")) {

            event.preventDefault();
            this.context.fetchApi("PATCH", `/quadro/mover`, {
                OrigemId: this.state.dragStart.id,
                DestinoId: this.state.dragOver.id
            })
                .then(() => {
                    this.refresh();
                })
                .catch(console.log)
                .finally(() => {
                    this.setState({ dragOver: null, dragStart: null });
                });
        }
    }

    share(){
        this.context.openModal("Compartilhar Dashboard", <DashboardShare url={`${process.env.REACT_APP_FRONTEND_URL}/?DashboardId=${this.props.data.id}`} onCancel={() => this.context.closeModal()} />);
    }

    LinkCSVFromQuadro(quadro){
        this.context.openModal("Link para CSV", <DashboardShare url={`${process.env.REACT_APP_BACKEND_URL}/quadro/${quadro.id}/csv`} onCancel={() => this.context.closeModal()} />);
    }

    render() {

        return <Container fluid className="h-100 w-100 d-flex flex-column overflow-auto print-visible">
            <h2 className="mt-2 d-flex justify-content-between flex-wrap">
                <div role="button">{this.props.data.nome}</div>
                <div className="text-nowrap">
                    <Form.Switch title="Atualizar Automaticamente" className="ms-2 d-inline-block d-print-none" size="sm" onChange={e => this.setState({ autoRefresh: e.target.checked })} checked={this.state.autoRefresh} />
                    <Button title="Imprimir" className="ms-2 d-print-none" size="sm" variant="outline-secondary" onClick={() => window.print()}>
                        <FontAwesomeIcon icon={faPrint} />
                    </Button>
                    <Button title="Download XLS" className="ms-2 d-print-none" size="sm" variant="outline-secondary" onClick={() => this.downloadXLSFromDashboard()}>
                        <FontAwesomeIcon icon={faFileExcel} />
                    </Button>
                    <Button title="Atualizar" className="ms-2 d-print-none" size="sm" variant={this.state.autoRefresh ? 'primary' : 'outline-primary'} onClick={() => this.refresh()}>
                        <FontAwesomeIcon icon={faRefresh} />
                    </Button>
                    {!this.props.data.GrupoId && <Button title="Compartilhar" className="ms-2 d-print-none" size="sm" variant="outline-info" onClick={() => this.share()}>
                        <FontAwesomeIcon icon={faShareNodes} />
                    </Button>}
                    {this.context.checkPermission(this.context.usuario, "Adicionar Quadro") &&
                        <Button title="Adicionar Quadro" className="ms-2 d-print-none" size="sm" variant="success" onClick={() => this.addQuadro()}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>}
                </div>
            </h2>
            <div className="text-small d-none d-md-block d-print-block" style={{textAlign: "justify"}}>{this.props.data.descricao}</div>
            <hr />
            <div className="grid flex-fill overflow-auto print-visible">
                {this.state.Quadros.map((quadro, key) =>
                    <div
                        key={key}
                        className={`d-flex flex-column grid-item grid-item-${quadro.tamanho ?? `1x1`}`}
                        style={{ order: quadro.ordem }}
                        onDragOver={e => this.dragOver(e, quadro)}
                        onDragLeave={() => this.dragLeave()}
                        onDrop={e => this.drop(e)}
                    >
                        <div
                            className={`d-flex quadro-title mb-1 text-white p-2 draggable dropzone ${quadro === this.state.dragStart ? 'bg-success' : (quadro === this.state.dragOver ? 'bg-info' : 'bg-danger')}`}
                            draggable={this.context.checkPermission(this.context.usuario, "Editar Dashboard")}
                            onDragStart={e => this.dragStart(e, quadro)}
                            onDragEnd={() => this.dragEnd()}>

                            <b>{quadro.titulo}</b>
                            <Dropdown className="ms-auto d-print-none">
                                <Dropdown.Toggle size="sm" variant="outline-danger" className="ms-2 text-white" />
                                <Dropdown.Menu>
                                    {this.context.checkPermission(this.context.usuario, "Editar Quadro") &&
                                        <Dropdown.Item size="sm" variant="outline-primary" onClick={() => this.editQuadro(quadro)}>
                                            <FontAwesomeIcon icon={faEdit} /> Editar
                                        </Dropdown.Item>}
                                    {this.context.checkPermission(this.context.usuario, "Configurar Quadro") &&
                                        <Dropdown.Item size="sm" variant="outline-info" onClick={() => this.configQuadro(quadro)}>
                                            <FontAwesomeIcon icon={faCogs} /> Configurar
                                        </Dropdown.Item>}
                                    <Dropdown.Item size="sm" variant="outline-success" onClick={() => this.downloadCSVFromQuadro(quadro)}>
                                        <FontAwesomeIcon icon={faFileCsv} /> Baixar CSV
                                    </Dropdown.Item>
                                    <Dropdown.Item size="sm" variant="outline-success" onClick={() => this.downloadXLSFromQuadro(quadro)}>
                                        <FontAwesomeIcon icon={faFileExcel} /> Baixar XLS
                                    </Dropdown.Item>
                                    <Dropdown.Item size="sm" variant="outline-success" onClick={() => this.LinkCSVFromQuadro(quadro)}>
                                        <FontAwesomeIcon icon={faLink} /> Link para CSV
                                    </Dropdown.Item>
                                    {this.context.checkPermission(this.context.usuario, "Excluir Quadro") &&
                                        <Dropdown.Item size="sm" variant="outline-danger" onClick={() => this.deleteQuadro(quadro)}>
                                            <FontAwesomeIcon icon={faTrash} /> Excluir
                                        </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="flex-fill overflow-auto print-visible">
                            <QuadroView quadro={quadro} />
                        </div>
                    </div>
                )}
            </div>
        </Container>
    }

}

export default DashboardView;

