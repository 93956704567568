import { Component } from "react";
import AppContext from "../context/AppContext";
import { Modal, ModalBody } from "react-bootstrap";

class AppModal extends Component {

    static contextType = AppContext;

    render() {
        return <Modal show={this.context.showModal} onHide={() => this.context.closeModal()} size={this.context.modalSize}>
            <Modal.Header closeButton>
                <Modal.Title>{this.context.modalTitle}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {this.context.modalContent}
            </ModalBody>
        </Modal>;
    }
}

export default AppModal;